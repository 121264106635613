<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <br/><br/><br/>
    <form-wizard ref="wizard" color="#3498db" @on-change="changeTab">
      <tab-content title="Upload file">
        <h1>Select a XLS file to validate and convert</h1>
        <img alt="Vue logo" src="../assets/img/fancy_upload.png" v-on:click="emitClickFile"/>
        <br/>
        <label>Transcending data file</label><br/>
        <input type="file" ref="input_file" name="input_file" id="input_file" v-on:change="onFileChange">
        <br/><br/>
        <label>GDSN data file (optional)</label><br/>
        <input type="file" ref="input_file_2" name="input_file_2" id="input_file_2" v-on:change="onFileChange2" :required=false>
      </tab-content>
      <tab-content title="Validate data">
        <div class="validation-not-valid" v-if="valid === true">
          <h3><img src="../assets/img/check-green.png"/>The data is valid!</h3>
          <div class="wrapper" style="background-color: transparent; border: none;">
            <table class="table-details" style="border: none">
              <tbody>
                <tr :class="altClass" v-for="(highResult, i) in validationResults" :key="i">
                  <td v-if="i === 'hierarchy'" style="border: none">
                    <p><img src="../assets/img/check-green.png" />
                    Hierarchy tests</p>
                  </td>
                  <td v-if="i !== 'hierarchy'" style="border: none">
                    <p><img src="../assets/img/check-green.png" />
                    GTIN: {{ i }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapper p20 w90">
            <h4>Select conversion format</h4>
            <input type="radio" value="GS1" checked name='format' @change="onChange($event)"/><label>Convert to GS1</label>
            <br/>
            <input type="radio" value="JSON" name='format' @change="onChange($event)"/><label>Convert to JSON</label>
            <br/>
            <input type="radio" disabled value="Convert to 1WS (soon)" name='format'/><label disabled>Convert to 1WS (soon)</label>
          </div>
        </div>
        <div class="validation-not-valid" v-if="valid === false">
          <h3><img src="../assets/img/cross-red.png" />The data is not valid!</h3>
          <div class="wrapper">
            <div v-for="(highResult, i) in validationResults" :key="i">
              <div v-for="result in highResult" :key="result.attribute" :class="'validation-entry ' + result.valid">
                <p><img src="../assets/img/check-green.png" v-if="result.valid === true"/><img src="../assets/img/cross-red.png" v-if="result.valid === false"/>Valid: {{ result.valid }}</p>
                <p>GTIN: {{ i }}</p>
                <p>ID: {{ result.id }}</p>
                <p>Attribute: {{ result.attribute }}</p>
                <p v-if="typeof result.error !== 'undefined'">Error: {{ result.error }}</p>
              </div>
            </div>
          </div>
        </div>
      </tab-content>
      <tab-content title="Generate CIN">
        <div class="w90 validation-not-valid" v-if="showDownload === 'GS1'">
          <h3><img src="../assets/img/gs1_logo.png"/>GS1 Format</h3>
          <div class="wrapper p20">
            <h3><img src="../assets/img/check-green.png"/>Success! Your XML is ready to download!</h3>
            <input type="button" value="Download XML" v-on:click="downloadGs1Data" />
          </div>
        </div>
        <div class="w90 validation-not-valid" v-else-if="showDownload === 'JSON'">
          <div class="wrapper p20">
            <h3><img src="../assets/img/check-green.png"/>Success! Your JSON is ready to download!</h3>
            <input type="button" value="Download JSON" v-on:click="downloadJsonData" />
          </div>
        </div>
      </tab-content>
      <tab-content title="Publish data">
        <div class="w90 validation-not-valid" v-if="showDownload === 'GS1'">
          <div class="wrapper p20 w90">
            <h4>Select publish destination</h4>
            <input type="radio" value="GS1-nl" checked name='publish-to'/><label>GS1 Netherlands (FTP)</label>
            <br/>
            <input type="radio" value="TrustedSource" name='publish-to'/><label>TrustedSource ZA</label>
            <br/>
          </div>
        </div>
      </tab-content>
      <div slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
           <wizard-button  v-if="props.activeTabIndex > 0" @click.native="prevClick" :style="props.fillButtonStyle">Previous</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button ref="next"
            :disabled="nextButtonDisabled"
            v-if="!props.activeTabIndex === 3"
            @click.native="nextClick"
            class="wizard-footer-right"
            :style="props.fillButtonStyle">Next</wizard-button>
          <wizard-button v-else @click.native="nextClick" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{ props.activeTabIndex === 3 ? 'Publish' : 'Next' }}</wizard-button>
        </div>
      </div>
    </form-wizard>
  </div>
</template>

<script>

import axios from 'axios'
import store from '@/store'
import { Config } from '../../config/index.js'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '../assets/scss/style.scss'

export default {
  name: 'Home',
  data: function () {
    return {
      input_file: null,
      input_file_2: null,
      valid: null,
      validationResults: [],
      validationId: null,
      gs1: null,
      showDownload: 'GS1',
      wizardStep: 0,
      nextButtonDisabled: true,
      counter: 0
    }
  },
  components: {
    FormWizard,
    TabContent,
    WizardButton
  },
  computed: {
    altClass () {
      this.incrementCounter()
      if (this.counter % 2 === 0) {
        return 'dark'
      }
      return 'ligt'
    }
  },
  watch: {
  },
  methods: {
    incrementCounter () {
      this.counter = this.counter + 1
    },
    changeTab (oldIndex, newIndex) {
      this.wizardStep = newIndex
      if (newIndex === 0 && this.input_file === null) {
        this.nextButtonDisabled = true
      }
      if (newIndex === 0 && this.input_file !== null) {
        this.nextButtonDisabled = false
      }
      if (newIndex === 1 && (this.valid === null || this.valid === false)) {
        this.nextButtonDisabled = true
      }
    },
    nextClick (e) {
      if (this.wizardStep === 0 && this.input_file !== null) {
        return this.validateClick(e)
      }
      if ((this.wizardStep === 1 || this.wizardStep === 2) && this.valid === true) {
        return this.$refs.wizard.nextTab()
      }
      if (this.wizardStep === 3 && this.valid === true) {
        return this.publish(e)
        // this.$router.push('/validations')
      }
    },
    prevClick (e) {
      this.wizardStep = this.wizardStep - 1
      this.$refs.wizard.prevTab()
    },
    publish (e) {
      axios
        .get(Config.APP_VALIDATION_API + '/publish/' + this.validationId)
        .then(response => {
          if (response.data === 'OK') {
            alert('Publication complete!')
          } else {
            alert(response.data)
          }
          this.$router.push('/validations')
        })
    },
    emitClickFile (e) {
      this.$refs.input_file.click()
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.input_file = files
      this.nextButtonDisabled = false
    },
    onFileChange2 (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.input_file_2 = files
    },
    onChange (e) {
      this.showDownload = e.target.value
    },
    resetFile (e) {
      this.input_file = null
    },
    downloadGs1Data (e) {
      const blob = new Blob([this.gs1], { type: 'application/xml' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'GS1_Converted.xml'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    downloadJsonData (e) {
      const blob = new Blob([JSON.stringify(this.json)], { type: 'application/json' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'JSON_Converted.json'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    validateClick (e) {
      const formData = new FormData()
      formData.append('payload', this.input_file[0])
      if (this.input_file_2 !== null) {
        formData.append('payload_2', this.input_file_2[0])
      }
      formData.append('scope', 'gs1-load')

      axios
        .post(Config.APP_VALIDATION_API + '/api/validate',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + store.state.auth
            }
          })
        .then(response => {
          if (response.data.payload.valid) {
            this.validationResults = response.data.payload.detail
            this.valid = true
          } else {
            this.valid = false
            this.validationResults = response.data.payload.detail
          }
          this.validationId = response.data.payload.validationId
          this.gs1 = response.data.payload.GS1
          this.json = response.data.payload.JSON
          this.$refs.wizard.nextTab()
          this.nextButtonDisabled = !this.valid
        })
    }
  },
  created: function () {
    if (store.state.auth === null) {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
