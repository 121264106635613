import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: null
  },
  mutations: {
  },
  actions: {
    setAuth (auth) {
      this.state.auth = auth
    }
  },
  modules: {
  }
})
